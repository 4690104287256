import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  onPageLoadGetButtons(call, userId, ce) {
    return this.getData(`mm/move_lpn_sort_initial?user_id=${userId}&ce=${ce}`, call);
  },
  wholeLPNTransfer(call, obj, showMessage) {
    return this.getData("/mm/move_lpn_sort_transfer_whole", call, obj, showMessage);
  },
  transferLPNIntransit(call, obj, showMessage) {
    return this.getData("/mm/move_lpn_sort_transfer", call, obj, showMessage);
  },
  moveLPNtoIntransit(call, obj, showMessage) {
    return this.getData("/mm/move_lpn_sort_intransit", call, obj, showMessage);
  },
  validationCheckLPNBCN(call, obj, showMessage) {
    return this.getData("/mm/move_lpn_sort_bcn", call, obj, showMessage);
  },
  getLPNContents(call, obj, showMessage) {
    return this.getData("/mm/move_get_lpn_contents", call, obj, showMessage);
  },
  lpnActivityReport(call, obj, showMessage) {
    return this.getData("/mm/lpn_activity_report", call, obj, showMessage);
  },
  projectListIntransitRpt(call, userId) {
    return this.getData(`mm/projectlist_intransit_report?user_id=${userId}`, call);
  },
  lpnTransitRptProj(call, projId) {
    return this.getData(`mm/lpn_intransit_rpt?proj_id=${projId}`, call);
  },
  lpnTransitRptLPN(call, projId, lpn) {
    return this.getData(`mm/lpn_intransit_rpt?proj_id=${projId}&lpn=${lpn}`, call);
  },
  lpnTransitDrill(call, lpn) {
    return this.getData(`mm/lpn_intransit_drill_mt?lpn_id=${lpn}`, call);
  },
  async getData(url, call, obj, showMessage) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res !== undefined) {
      if (res.data.body == null) {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse('{"message":"NA"}');
      }
      if (res.data.body.message != undefined || res.data.body.message != null) {
        if (!res?.data?.body?.message) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(res.data.body.message),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = res.data.body.message;
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return {};
        }
        try {
          if (showMessage) {
            let Alert = {
              type: "success",
              isShow: true,
              message: res.data.message,
            };
            store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return JSON.parse(res?.data?.body?.message);
        } catch (err) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(err),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = err.message;
          return {};
        }
      } else if (res.data.body.message == null) {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse('{"message":"NA"}');
      } else {
        if (!res?.data?.message) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(res.data.message),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = res.data.message;
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return {};
        }
        try {
          if (showMessage) {
            let Alert = {
              type: "success",
              isShow: true,
              message: res.data.message,
            };
            store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return JSON.parse(res?.data?.message);
        } catch (err) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(err),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = err.message;
          return {};
        }
      }
    }
  },
};

import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import materialService from "../js/material-movement-service";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      selectedProject: "",
      projectList: [],
      runProject: false,
      runLPN: false,
      lpnSearchNum: "",
      showData: false,
      intransitLPNData: [],
      headerIntransitLPN: [
        { text: "In-Transit LPN", align: "start", value: "In-Transit LPN", class: "primary customwhite--text" },
        { text: "Quantity on LPN", value: "Quantity on LPN", class: "primary customwhite--text" },
        { text: "Linked To User", value: "Linked To User", class: "primary customwhite--text" },
        { text: "Most Recent Activity on LPN", value: "Most Receent Activity on LPN", class: "primary customwhite--text" },
        { text: "Actions", value: "Actions", class: "primary customwhite--text" },
        { text: "Details", value: "Details", class: "primary customwhite--text" },
      ],
      searchLPNRule: [(v) => !!v || "Field is required", (value) => /^1-\d{3}(-\d{3})?$/.test(value) || "Enter a correct format: X-XXX or X-XXX-XXX"],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProject();
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.selectedProject = "";
      this.projectList = [];
      this.runProject = false;
      this.runLPN = false;
      this.lpnSearchNum = "";
      this.showData = false;
      this.intransitLPNData = [];
      this.getProject();
    },
    //Project Data
    async getProject() {
      let projData = await materialService.projectListIntransitRpt("get", this.userId);
      this.projectList = projData;
    },
    //Run Report for Project
    async projectData() {
      let data = await materialService.lpnTransitRptProj("get", this.selectedProject);
      if (data.message !== "NA") {
        const newArr = data.map((obj) => {
          return {
            ...obj,
            "Most Receent Activity on LPN": Utility.convertESTToLocal(obj["Most Receent Activity on LPN"]),
          };
        });
        this.intransitLPNData = newArr;
        this.showData = true;
        this.$refs.searchForm.resetValidation();
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No records based on search criteria",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.intransitLPNData = [];
        this.showData = false;
        this.runProject = false;
        this.$refs.searchForm.resetValidation();
      }
    },
    //Run Report for LPN
    async lpnData() {
      let data = await materialService.lpnTransitRptLPN("get", 0, this.lpnSearchNum);
      if (data.message !== "NA") {
        const newArr = data.map((obj) => {
          return {
            ...obj,
            "Most Receent Activity on LPN": Utility.convertESTToLocal(obj["Most Receent Activity on LPN"]),
          };
        });
        this.intransitLPNData = newArr;
        this.showData = true;
        this.$refs.searchForm.resetValidation();
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No records based on search criteria",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.intransitLPNData = [];
        this.showData = false;
        this.runLPN = false;
        this.$refs.searchForm.resetValidation();
      }
    },
    //LPN details based on click event
    async lpnDetails(item) {
      const regex = /@lpn_id=(\d+)/;
      const match = regex.exec(item.drill_query);
      let data = await materialService.lpnTransitDrill("get", match[1]);
      if (data.message !== "NA") {
        console.log("data",data);
      }
    },
    //Format Number
    formatNumber(evt) {
      // Remove any non-numeric characters except for the initial digit
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 45) {
        evt.preventDefault();
      } else {
        let numbers = this.lpnNum.replace(/[^0-9]/g, "");
        if (numbers.length === 0) {
          this.lpnNum = "";
          return;
        }
        // Extract the initial digit(s) as the prefix
        let prefix = numbers[0];
        numbers = numbers.slice(1);
        // Format the remaining digits
        if (numbers.length <= 3) {
          this.lpnSearchNum = `${prefix}-${numbers}`;
        } else {
          let formatted = `${prefix}-${numbers.slice(0, 3)}`;
          for (let i = 3; i < numbers.length; i += 3) {
            formatted += `-${numbers.slice(i, i + 3)}`;
          }
          this.lpnSearchNum = formatted;
        }
      }
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
  },
};
